import React from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import RenderTags from "../components/RenderTags"
import kebabCase from "lodash/kebabCase"
import SEO from "../components/SEO";

class BlogDetail extends React.Component {
    render() {
        const { data } = this.props
        const { html } = data.markdownRemark
        const { title, author, publishedAt, slug, description } = data.markdownRemark.frontmatter
        // const { subTitle, featuredImg } = data.markdownRemark.frontmatter
        const { group } = data.allMarkdownRemark
        const tagsdetail = data.tagsdetail.group
        const authordetail = data.authordetail.nodes
        const allposts = data.allposts.nodes

        let relatedarticle;
        
        function getAllPostsContainContextTags(allposts, tagsdetail) {
            const getallpostsdetail = allposts.map((res) => ( // get all posts detail
                res.frontmatter
            ))

            const detailTagArr = tagsdetail.map((res) => ( // get tags array following context
                res.fieldValue
            ))

            relatedarticle = getallpostsdetail.filter(item => item.tags.some(item => detailTagArr.includes(item))) // get posts that tags property have contained tags in context
            
            return relatedarticle;
        }

        getAllPostsContainContextTags(allposts, tagsdetail)

        function commentSignUp() {
            document.querySelector(".app-section-detailblogs-comment .app-section-detailblogs-comment-detail").style.display = "block";
            document.querySelector(".app-section-detailblogs-comment .app-section-detailblogs-comment-login").style.display = "none";
        }

        return (
            <Layout>
                <SEO title={title} description={description} meta={[{ name: `keywords`, content: ["blogs"] }]} pathname={"/support/blogs/" + slug} />
                <div className="main-content-padding">
                    {/* <div className="banner-page">
                        <Container>
                            <h1>Blogs</h1>
                            <h2>Main content blogs</h2>
                        </Container>
                    </div>
                    <div className="blog-detail">
                        <Container>
                            <h1>{title}</h1>
                            <h2>{subTitle}</h2>
                            <div className="img-feature">
                                <Img fluid={featuredImg.childImageSharp.fluid} />
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: html }} />
                        </Container>
                    </div> */}
                    <div className="app-general-seciton app-section-detailblogs">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={12} xl={1}></Col>
                                <Col xs={12} md={12} lg={12} xl={8}>
                                    <div className="app-blog-posts-directory">
                                        <h6><Link to="/support/blogs">Blog Home</Link></h6>
                                        <img src="/app-icon-blog-posts-directory.svg" alt="app icon blog posts directory img" />
                                        <h6>{title}</h6>
                                    </div>
                                    <div className="app-detailblogs-detail-left">
                                        <h2 className="title">{title}</h2>
                                        <div className="app-detailblogs-detail-infor-article">
                                            <div className="app-detailblogs-detail-infor-article-left">
                                                <div className="app-detailblogs-detail-infor-article-content">
                                                    <img src="/app-icon-user.svg" alt="app icon user" />
                                                    <h6>{author}</h6>
                                                </div>
                                                <div className="app-detailblogs-detail-infor-article-content">
                                                    <img src="/app-icon-calendar.svg" alt="app icon calendar" />
                                                    <h6>{publishedAt}</h6>
                                                </div>
                                            </div>
                                            {/* <div className="app-detailblogs-detail-infor-article-right">
                                                <div className="app-detailblogs-detail-infor-article-content">
                                                    <img src="/app-icon-like.svg" alt="app icon like" />
                                                    <h6>60 Likes</h6>
                                                </div>
                                                <div className="app-detailblogs-detail-infor-article-content">
                                                    <img src="/app-icon-view.svg" alt="app icon view" />
                                                    <h6>120 Views</h6>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="markdown-body" dangerouslySetInnerHTML={{ __html: html }} />
                                        {/* <div className="app-detailblogs-detail-interaction">
                                            <img src="/app-icon-like.svg" alt="app icon like" />
                                            <h6>Like</h6>
                                        </div> */}
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={12} xl={3}>
                                    <div className="app-blogs-detailblogs-right">
                                        <h5>Tags</h5>
                                        <div className="app-blogs-detailblogs-right-tag">
                                            {tagsdetail.map((res) => (
                                                <Link to={`/tags/${kebabCase(res.fieldValue)}/`} key={res.fieldValue}>
                                                    {res.fieldValue}
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="app-blogs-detailblogs-right">
                                        <h5>Related Articles</h5>
                                        {relatedarticle.map((res) => (slug !== res.slug) ? (
                                            <div className="app-blogs-detailblogs-right-author-related-article" key={res.slug}>
                                                <Link to={"/support/blogs/" + res.slug}>
                                                    {res.title}
                                                </Link>
                                                <div className="app-blogs-detailblogs-right-author">
                                                    <div className="app-blogs-detailblogs-right-author-infor">
                                                        <img src="/app-icon-user.svg" alt="app icon user" />
                                                        <h6>{res.author}</h6>
                                                    </div>
                                                    <div className="app-blogs-detailblogs-right-calendar-infor">
                                                        <img src="/app-icon-calendar.svg" alt="app icon calendar" />
                                                        <h6>{res.publishedAt}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null)}
                                    </div>
                                    <div className="app-blogs-detailblogs-right">
                                        <h5>This Author</h5>
                                        {authordetail.map((res) => (slug !== res.frontmatter.slug) ? (
                                            <div className="app-blogs-detailblogs-right-author-related-article" key={res.id}>
                                                <Link to={"/support/blogs/" + res.frontmatter.slug}>
                                                    {res.frontmatter.title}
                                                </Link>
                                                <div className="app-blogs-detailblogs-right-author">
                                                    <div className="app-blogs-detailblogs-right-author-infor">
                                                        <img src="/app-icon-user.svg" alt="app icon user" />
                                                        <h6>{res.frontmatter.author}</h6>
                                                    </div>
                                                    <div className="app-blogs-detailblogs-right-calendar-infor">
                                                        <img
                                                            src="/app-icon-calendar.svg"
                                                            alt="app icon calendar"
                                                        />
                                                        <h6>{res.frontmatter.publishedAt}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null )}
                                    </div>
                                    <div className="app-blogs-detailblogs-right">
                                        <RenderTags group={group} />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="app-section-detailblogs-comment">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={1}></Col>
                                <Col xs={12} md={12} lg={7}>
                                    {/* <h6 className="app-section-detailblogs-comment-login" onClick={commentSignUp} onKeyDown={commentSignUp}>
                                        Sign in to comment
                                    </h6> */}
                                    <div className="app-section-detailblogs-comment-detail">
                                        <h4>Comment</h4>
                                        <textarea></textarea>
                                        <input type="button" value="Post" />
                                        <h5>4 comments</h5>
                                        <div className="app-section-detailblogs-comment-detail-history">
                                            <Row>
                                                <Col xs={12} md={12} lg={1}>
                                                    <img src="/app-icon-black.svg" alt="app avatar" />
                                                </Col>
                                                <Col xs={12} md={12} lg={11}>
                                                    <div className="app-section-detailblogs-comment-detail-history-header">
                                                        <h6>Esther Howard</h6>
                                                        <h6 className="app-section-detailblogs-comment-detail-history-header-time">30/06 10:30AM</h6>
                                                    </div>
                                                    <h6>Morbi viverra nequeipsum, at interdum quam fringilla porta. Duis
                                                        dictum faucibus molestie. Proinvenenatis molestie diam,
                                                        id iaculis purusluctusac. Suspendisse arisusiaculis, 
                                                        mattis misitamet, egestas dui.Morbiin felistincidunt,
                                                        efficitur ipsum non,hendrerit tortor.</h6>
                                                    <div className="app-section-detailblogs-comment-detail-history-interaction">
                                                        <div className="app-detailblogs-comment-detail-history-interaction">
                                                            <img src="/app-icon-like.svg" alt="app icon like" />
                                                            <h6>60</h6>
                                                        </div>
                                                        <h6 className="app-detailblogs-comment-detail-history-interaction-text">
                                                            Like
                                                        </h6>
                                                        <h6 className="app-detailblogs-comment-detail-history-interaction-text">
                                                            Reply
                                                        </h6>
                                                        <h6 className="app-detailblogs-comment-detail-history-interaction-text">
                                                            Quote
                                                        </h6>
                                                        <h6 className="app-detailblogs-comment-detail-history-interaction-text">
                                                            Follow
                                                        </h6>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="app-section-detailblogs-comment-detail-history">
                                            <Row>
                                                <Col xs={12} md={12} lg={1}>
                                                    <img src="/app-icon-black.svg" alt="app avatar" />
                                                </Col>
                                                <Col xs={12} md={12} lg={11}>
                                                    <div className="app-section-detailblogs-comment-detail-history-header">
                                                        <h6>Esther Howard</h6>
                                                        <h6 className="app-section-detailblogs-comment-detail-history-header-time">30/06 10:30AM</h6>
                                                    </div>
                                                    <h6>Morbi viverra nequeipsum, at interdum quam fringilla 
                                                        porta. Duis dictum faucibus molestie. Proin venenatis 
                                                        molestie diam,id iaculis purus luctusac. Suspendisse a
                                                        risusiaculis, mattis misitamet, egestas dui. Morbiin 
                                                        felis tincidunt, efficitur ipsum non, hendrerit tortor.</h6>
                                                    <div className="app-section-detailblogs-comment-detail-history-interaction">
                                                        <div className="app-detailblogs-comment-detail-history-interaction">
                                                            <img src="/app-icon-like.svg" alt="app icon like" />
                                                            <h6>60</h6>
                                                        </div>
                                                        <h6 className="app-detailblogs-comment-detail-history-interaction-text">
                                                            Like
                                                        </h6>
                                                        <h6 className="app-detailblogs-comment-detail-history-interaction-text">
                                                            Reply
                                                        </h6>
                                                        <h6 className="app-detailblogs-comment-detail-history-interaction-text">
                                                            Quote
                                                        </h6>
                                                        <h6 className="app-detailblogs-comment-detail-history-interaction-text">
                                                            Follow
                                                        </h6>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="app-section-detailblogs-comment-detail-history">
                                            <Row>
                                                <Col xs={12} md={12} lg={1}>
                                                    <img src="/app-icon-black.svg" alt="app avatar" />
                                                </Col>
                                                <Col xs={12} md={12} lg={11}>
                                                    <div className="app-section-detailblogs-comment-detail-history-header">
                                                        <h6>Esther Howard</h6>
                                                        <h6 className="app-section-detailblogs-comment-detail-history-header-time">30/06 10:30AM</h6>
                                                    </div>
                                                    <h6>Morbi viverra nequeipsum, at interdum quam
                                                        fringilla porta. Duisdictum faucibusmolestie. 
                                                        Proinvenenatis molestie diam, id iaculis purus
                                                        luctusac. Suspendisse arisusiaculis, mattis mi
                                                        sitamet, egestas dui. Morbiin felis tincidunt,
                                                        efficitur ipsum non, hendrerit tortor.</h6>
                                                    <div className="app-section-detailblogs-comment-detail-history-interaction">
                                                        <div className="app-detailblogs-comment-detail-history-interaction">
                                                            <img src="/app-icon-like.svg" alt="app icon like" />
                                                            <h6>60</h6>
                                                        </div>
                                                        <h6 className="app-detailblogs-comment-detail-history-interaction-text">
                                                            Like
                                                        </h6>
                                                        <h6 className="app-detailblogs-comment-detail-history-interaction-text">
                                                            Reply
                                                        </h6>
                                                        <h6 className="app-detailblogs-comment-detail-history-interaction-text">
                                                            Quote
                                                        </h6>
                                                        <h6 className="app-detailblogs-comment-detail-history-interaction-text">
                                                            Follow
                                                        </h6>
                                                    </div>
                                                    <div className="app-detailblogs-comment-reply-detail-history">
                                                        <Row>
                                                            <Col xs={12} md={12} lg={1}>
                                                                <img src="/app-icon-black.svg" alt="app avatar" />
                                                            </Col>
                                                            <Col xs={12} md={12} lg={11}>
                                                                <div className="app-detailblogs-comment-reply-detail-history-header">
                                                                    <h6>Esther Howard</h6>
                                                                    <h6 className="app-detailblogs-comment-reply-detail-history-header-time">30/06 10:30AM</h6>
                                                                </div>
                                                                <h6>Morbi viverra neque ipsum, at interdum quam
                                                                    fringillaporta. Duisdictum faucibus molestie.
                                                                    Proinvenenatis molestiediam, idiaculis purus
                                                                    luctusac. Suspendissea risusiaculis, mattis 
                                                                    misitamet, egestas dui.Morbi infelistincidunt,
                                                                    efficituripsum non, hendrerit tortor.</h6>
                                                                <div className="app-section-detailblogs-comment-reply-detail-history-interaction">
                                                                    <div className="app-detailblogs-comment-reply-detail-history-interaction">
                                                                        <img src="/app-icon-like.svg" alt="app icon like" />
                                                                        <h6>112</h6>
                                                                    </div>
                                                                    <h6 className="app-detailblogs-comment-reply-detail-history-interaction-text">
                                                                        Like
                                                                    </h6>
                                                                    <h6 className="app-detailblogs-comment-reply-detail-history-interaction-text">
                                                                        Reply
                                                                    </h6>
                                                                    <h6 className="app-detailblogs-comment-reply-detail-history-interaction-text">
                                                                        Quote
                                                                    </h6>
                                                                    <h6 className="app-detailblogs-comment-reply-detail-history-interaction-text">
                                                                        Follow
                                                                    </h6>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={4}></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default BlogDetail

export const query = graphql`
    query BlogDetail($slug: String, $author: String) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                subTitle
                author
                publishedAt(formatString: "MMM Do, YYYY")
                slug
                description
                featuredImg {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        tagsdetail: allMarkdownRemark(
            filter: { frontmatter: { slug: { eq: $slug } } }
        ) {
            group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }
        authordetail: allMarkdownRemark(
            filter: { frontmatter: { author: { eq: $author } }, fields: { draft: { eq: false } } }
            sort: { fields: frontmatter___publishedAt, order: DESC }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    author
                    publishedAt(formatString: "MMM Do, YYYY")
                    slug
                }
                id
            }
        }
        allposts: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "blogs_all_posts" } }, fields: { draft: { eq: false } } }
            sort: { fields: frontmatter___publishedAt, order: DESC }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    author
                    publishedAt(formatString: "MMM Do, YYYY")
                    slug
                    tags
                }
                id
            }
        }
        allMarkdownRemark(
            filter: { fields: { draft: { eq: false } } }
        ) {
            group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }
    }
`
